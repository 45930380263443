/*Sign up Form Field*/
.signup-container{
    position: relative;
    height: auto;
    top: 92px;
    width: 100%;
    background-color: #ffffff;
}

.signup-wrapper{
    margin: 20px; 
    width: auto;
}

.signup-modal-header{
	font-size: var(--mob-header2);
	font-weight: 800;
    color: var(--primary-color);
}

.signup-modal-subheader{
	color: #5E6373;
	font-size: 16px;
	margin-top: 16px;
	line-height: 24px;
}

.signup-input-fields{
	position: relative;
	width: 100%;
	margin: 32px 0px;
}

.signup-input:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.signup-input:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.signup-input-fields li:last-child{
	margin-bottom: 0;
}

.signup-input{
	width: 100%;
	font-size: 16px;
	background-color: #5b5e6600;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.signup-password{
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.signup-password-input{
	width: 100%;
	font-size: 16px;
}

.signup-password:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.signup-password:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.signup-modal-ctas{
	width: 100%;
}

.signup-primary-cta{
	width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    border-radius: 60px; 
    cursor: pointer;
	margin-bottom: 12px;
    padding: 20px;
}

.signup-secondary-cta{
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 12px;
    border: 1.5px solid #502274;
    padding: 20px;
    border-radius: 60px; 
    cursor: pointer;
}

.signup-google-icon{
	width: 24px;
	height: 24px;
}

.signup-google-signup{
	background-color: var(--bg-color);
	font-size: 16px;
	font-weight: 600;
	color: #502274;
}

.signup-redirect-cta{
    text-align: center;
    padding: 32px;
}

.signup-redirect-link{
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    margin-left: 8px;
}