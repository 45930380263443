* {
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	box-sizing: border-box;
	font-family: 'gilroy', sans-serif;
	list-style: none;
	text-decoration: none;
    -webkit-appearance: none;
	user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}


/*Remove link properties*/
a:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

a:visited {
    text-decoration: inherit;
    color: inherit;
}



body {
	background-color:var(--bg-color);
	color: var(--text-color);
	font-family: -apple-system, BlinkMacSystemFont, 'gilroy', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*Variables for main styling and brand*/
:root {
	/*Main Brand Colors*/
	--bg-color: #fff;
	--text-color: #1E2330;
	--inactive-text-color: #9CA1B1;
	--primary-color: #502274;
	--primary-color-hover: #6C2A85;
	--primary-color-50: #F2E6F3;
	--primary-color-100: #E0C0E1;
	--secondary-color: #DDBDFF;
	--border-color: #BABFD1;


	/*Comlimentary Colors*/
	--airtime-primary-color:#733770;
	--airtime-secondary-color:#FFA7C5;

	--utility-primary-color:#5D3F27;
	--utility-secondary-color:#FFB835;

	--tv-primary-color:#66664B;
	--tv-secondary-color:#B3E846;

	--voucher-primary-color:#3b5998;
	--voucher-secondary-color:#1877f2;


	/*Border outline*/
	--border: 1px solid #BABFD1;

	/*Box shadows*/
	--nav-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);

	/*Typography Mobile View*/
	--mob-header3: 42px;
	--mob-header2: 32px;
	--mob-header1: 20px;
	--mob-reg: 16px;

	/*Typography Desktop View*/
	--desktop-header: 24px;
	--desktop-sub-header: 20px;
	--desktop-reg-text: 16px;
}



/* Main primary button styling*/
.main-primary-button{
	height: 56px;
	width: 100%;
	background-color: var(--primary-color);
	border-radius: 60px;
	color: var(--bg-color);
	font-size: 16px;
}

.main-secondary-button{
	width: 100%;
	height: 56px;
	border: 1.5px solid #502274;
	background-color: var(--bg-color);
	border-radius: 60px;
	color: var(--primary-color);
	font-size: 16px;
}




/* Main footer styling*/
.main-footer{
	background-color: var(--secondary-color);
	padding: 20px;
	width: 100%;
	margin-top: 48px;
}

.main-footer-wrapper{
	width: 100%;
	color: var(--primary-color);
}

.main-footer-details{
	list-style: none;
	text-decoration: none;
}

.main-footer-details li{
	padding: 12px 0px;
	margin-bottom: 24px;
	font-size: 16px;
	font-weight: 600;
}

.main-footer-logo{
	width: 80px;
	height: 34px;
}

.main-footer-copyright{
	font-size: 14px;
	width: 100%;
	text-align: center;
	margin-top: 64px;
}






/*Main Nav Bar Css*/
.navbar{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    padding: 20px;
    box-shadow: var(--nav-shadow);
    background-color: #ffffff;
}

.navbar-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swiff-logo{
    width: 78px;
    height: 34px;
}

.nav-ctas{
    display: flex;
    align-items: center;
}

.signup-cta{
    line-height: 24px;
    padding: 8px 16px;
    background-color: var(--primary-color);
    border-radius: 60px;
    color: var(--bg-color);
    font-weight: 500;
    cursor: pointer;
	margin-left: 16px;
}

.signup-cta:hover{
    background-color: var(--primary-color-hover);
    transition: all .3s;
    transition-timing-function: ease-in;
}

.signup-cta:active{
    background-color: var(--primary-color-hover);
}

.login-cta{
    line-height: 24px;
    padding: 8px 16px;
    border: 1.5px solid #502274;
    background-color: var(--bg-color);
    border-radius: 60px;
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
	margin-left: 16px;
}

.login-cta:hover{
    background-color: var(--primary-color-50);
    transition: all .3s;
    transition-timing-function: ease-in;
}

.login-cta:active{
    background-color: var(--primary-color-50);
}


/*Profile circle styling */
.profile-cirlce-wrap{
	display: flex; /*Display to flex */
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background-color: var(--primary-color-50);
	border-radius: 100px;
}

.profile-cirlce{
	font-size: 14px;
	font-weight: 600;
}







/*Purchase Page Nav Bar Css*/
.navbar-wrapper2{
	margin-top: 32px;
}

.edit-and-curency{
	display: flex;
	width: 100%;
	align-items: center;
	font-size: 14px;
	gap: 12px;
}

.edit-recipient{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--bg-color);
	padding: 8px 8px 8px 14px;
	border: var(--border);
	border-radius: 60px;
	cursor: pointer;
}

.flag-and-num{
	display: flex;
	align-items: center;
	gap: 8px;
}

.country-flag2{
	width: 24px;
	height: 24px;
}

.select-currency{
	width: auto;
	display: flex;
	gap: 4px;
}

.recipient-num{
	font-weight: 800;
}

.edit-icon{
	background-color: var(--primary-color-50);
	border-radius: 60px;
	padding: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.select-currency{
	position: relative;
	height: 54px;
	display: flex;
	align-items: center;
	padding: 0px 20px;
	border-radius: 60px;
	border: 1px solid #502274;
	cursor: pointer;
}

.currency{
	font-weight: 800;
}

.select-product{
	position: relative;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	background-color: var(--primary-color-50);
	border: 1.5px solid #E0C0E1;
	border-radius: 60px;
	margin-top: 24px;
	cursor: pointer;
	z-index: 2;
}

.selected-product{
	font-weight: 600;
}

.select-utility{
	position: relative;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	background-color: var(--secondary-color);
	border: 1.5px solid #3e0350;
	border-radius: 60px;
	margin-top: 24px;
	cursor: pointer;
	z-index: 1;
}

.select-ecg{
	position: relative;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	background-color: var(--airtime-secondary-color);
	border: 1.5px solid #8b6a95;
	border-radius: 60px;
	margin-top: 24px;
	cursor: pointer;
	z-index: 0;
}




.utilities-flag{
	margin-bottom: 8px;
    object-fit: cover;
    width:15%;
    height: 15%;
    border-radius: 50%;	
}



/*Country drop down component styling*/
.country-dropdown{
	display: block; /* display to flex */
    position: absolute;
    top: 64px;
    left: 0;
    background-color: var(--text-color);
    color: var(--bg-color);
    border-radius: 20px;
    width: 100%;
}

.country-dropdown-wrapper{
    padding: 24px;
    width: 100%;
    max-height: 210px;
    overflow-y: scroll;
    transition: all 0.4s;
}

.country-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.country-row:last-child{
    margin-bottom: 0px;
}

.country{
    display: flex;
    align-items: center;
}

.country-name{
    margin-left: 12px;
}

.flag-code{
    font-weight: 600;
}

/* width */
.country-dropdown-wrapper::-webkit-scrollbar {
    width: 8px;
    padding-right: 20px;
  }
  
  /* Track */
  .country-dropdown-wrapper::-webkit-scrollbar-track {
    background: #3F4453; 
    border-radius: 60px;
    margin-block: 16px;
  }
   
  /* Handle */
  .country-dropdown-wrapper::-webkit-scrollbar-thumb {
    background: #9CA1B1;
    border-radius: 60px;
  }
  
  /* Handle on hover */
  .country-dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }





/*Currency drop down component styling*/
.currency-dropdown{
	display: block; /* display to flex */
    position: absolute;
    top: 64px;
    left: 0;
    background-color: var(--text-color);
    color: var(--bg-color);
    border-radius: 20px;
    width: 100%;
	z-index: 3;
}

.currency-dropdown-wrapper{
    padding: 24px;
    width: 100%;
    max-height: 210px;
    overflow-y: scroll;
    transition: all 0.4s;
}

.currency-row{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.currency-row:last-child{
    margin-bottom: 0px;
}

.flag-code{
    font-weight: 600;
}

/* width */
.currency-dropdown-wrapper::-webkit-scrollbar {
    width: 8px;
    padding-right: 20px;
  }
  
  /* Track */
  .currency-dropdown-wrapper::-webkit-scrollbar-track {
    background: #3F4453; 
    border-radius: 60px;
    margin-block: 16px;
  }
   
  /* Handle */
  .currency-dropdown-wrapper::-webkit-scrollbar-thumb {
    background: #9CA1B1;
    border-radius: 60px;
  }
  
  /* Handle on hover */
  .currency-dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }






/*Product drop down component styling*/
.product-dropdown{
	display: flex; /* display to flex */
    position: absolute;
    top: 64px;
    left: 0;
    background-color: var(--text-color);
    color: var(--bg-color);
    border-radius: 32px;
    width: 100%;
	overflow-y: scroll;
	transition: all 0.4s;
}

.product-dropdown-wrapper{
    padding: 24px;
    width: 100%;
	
}

.product-row{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.product-row:last-child{
    margin-bottom: 0px;
}

.product{
	width: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 8px 24px;
	border-radius: 60px;
	cursor: pointer;
}

.product-icon{
	filter: grayscale(100%);
}

.product:hover{
	background-color: #3F4453;
	transition: all .3s;
    transition-timing-function: ease-in;
	color: var(--bg-color);
}

.product:focus{
	background-color: #3F4453;
	transition: all .3s;
    transition-timing-function: ease-in;
	color: var(--bg-color);
}







/* Edit Modal Styling */
.edit-modal{
    display: block; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
}

.edit-modal-wrapper{
    width: 85%;
    position: relative;
    border-radius: 24px;
    background-color: #ffffff;
	padding: 24px;
	top:30%;
	margin: 0 auto;
}

.edit-modal-nav{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;
}

.edit-modal-header{
	font-size: var(--mob-header1);
	font-weight: 800;
}

.edit-modal-cancel{
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.selected-network{
	display: none;
	justify-content: space-between;
	align-items: center;
	border: var(--border);
	padding: 8px 16px 8px 8px;
	border-radius: 60px;
}

.selected-network-detail{
	display: flex;
	align-items: center;
	gap: 16px;
}

.network-name{
	font-size: 14px;
	font-weight: 600;
}

.network-logo{
	width: 40px;
	height: 40px;
}

.edit-modal-cta{
	width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 60px; 
    cursor: pointer;
	margin-top: 32px;
}

.edit-recipient-input{
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid #BABFD1;
	border-radius: 60px;
	padding: 8px 20px 8px 8px;
	width: 100%;
}





/* Sending swiffly modal */
.sending-modal{
    display: block; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
}

.sending-modal-wrapper{
    width: 85%;
    position: relative;
    border-radius: 24px;
    background-color: #ffffff;
	padding: 24px;
	top:40%;
	margin: 0 auto;
}

.sending-gif-container{
	height: 100px;
}

.sending-gif{
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.sending-modal-title{
	text-align: center;
	margin-bottom: 32px;
	font-weight: 800;
	font-size: 20px;
}

.sending-modal-dynamic-text{
	text-align: center;
	margin-bottom: 32px;
}

.dynamic-number{
	font-weight: 600;
}

.dynamic-amount{
	font-weight: 600;
}





/* Loader modal */
.loader-modal{
    display: block; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
	margin:0 auto;
}

.loader-modal-wrapper{
    position: relative;
    border-radius: 24px;
	padding: 24px;
	top:25%;
	margin: 0 auto;
}

.loader-gif{
	height: 350px;
	width: 350px;
	object-fit: cover;
	padding-bottom: 64px;
}






/*Payment Succesfull modal */
.success-modal{
    display: block; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
}

.success-modal-wrapper{
    width: 85%;
    position: relative;
    border-radius: 24px;
    background-color: #ffffff;
	padding: 24px;
	margin-bottom: 48px;
	top:30%;
	margin: 0 auto;
	
}

.success-gif-container{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.success-gif{
	height: 120px;
	width: 120px;
	object-fit: cover;
	margin-bottom: 32px;
}

.success-modal-title{
	text-align: center;
	margin-bottom: 32px;
	font-weight: 800;
	font-size: 20px;
}

.success-modal-dynamic-text{
	text-align: center;
	margin-bottom: 32px;
}






/*Receipt Form Field modal */
.receipient-modal{
    display: flex; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
}

.receipient-modal-wrapper{
    width: 90%;
    position: relative;
    border-radius: 24px;
    background-color: #ffffff;
	padding: 20px;
}

.receipient-modal-nav{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.receipient-modal-header{
	font-size: var(--mob-header1);
	font-weight: 800;
}

.receipient-modal-cancel{
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.receipient-modal-subheader{
	color: #5E6373;
	font-size: 16px;
	margin-top: 24px;
	line-height: 24px;
}

.receipient-input-fields{
	position: relative;
	width: 100%;
	margin: 32px 0px;
}

.receipient-input:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.receipient-input:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.receipient-input-fields li:last-child{
	margin-bottom: 0;
}

.receipient-input{
	width: 100%;
	font-size: 16px;
	background-color: #5b5e6600;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.receipient-password{
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.receipient-password-input{
	width: 100%;
	font-size: 16px;
}

.receipient-password:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.receipient-password:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.receipient-modal-ctas{
	width: 100%;
}

.receipient-primary-cta{
	width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    border-radius: 60px; 
    cursor: pointer;
	margin-bottom: 12px;
}

.receipient-secondary-cta{
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 12px;
    border: 1.5px solid #502274;
    padding: 20px;
    border-radius: 60px; 
    cursor: pointer;
}

.receipient-google-icon{
	width: 24px;
	height: 24px;
}

.receipient-google-signup{
	background-color: var(--bg-color);
	font-size: 16px;
	font-weight: 600;
	color: #502274;
}






/*Promo Modal Styling*/
.promo-modal{
    display: flex; /* display to flex */
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(30, 35, 48, 0.9);
}

.promo-modal-wrapper{
    width: 85%;
    position: relative;
    border-radius: 24px;
    background-color: #ffffff;
	padding: 24px;
	top:10%;
	margin: 0 auto;
}

.promo-modal-nav{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;
}

.promo-modal-header{
	font-size: var(--mob-header1);
	font-weight: 800;
	display: flex;
	align-items: center;
	gap: 12px;
}

.promo-free{
	color: #F87D2D;
	font-weight: 800;
	margin-top: 8px;
}

.promo-modal-cancel{
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.promo-modal-title{
	text-align: center;
	margin-bottom: 32px;
	font-weight: 800;
	font-size: 20px;
}

.selected-network{
	display: none;
	justify-content: space-between;
	align-items: center;
	border: var(--border);
	padding: 8px 16px 8px 8px;
	border-radius: 60px;
}

.selected-network-detail{
	display: flex;
	align-items: center;
	gap: 16px;
}

.network-name{
	font-size: 14px;
	font-weight: 600;
}

.network-logo{
	width: 40px;
	height: 40px;
}

.promo-modal-cta{
	width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 60px; 
    cursor: pointer;
	margin-top: 32px;
}

.promo-recipient-input{
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid #BABFD1;
	border-radius: 60px;
	padding: 8px 20px 8px 8px;
	width: 100%;
}



/* Utilities Account Number */

.utility-acc-input-fields{
	position: relative;
	width: 100%;
	margin-top: 22px;
	z-index: 0;
}




.utility-acc-signup-input{
	width: 100%;
	font-size: 16px;
	background-color: #5b5e6600;
	padding: 18px 23px;
	border: var(--border);
	border-radius: 60px;
}

.utility-acc-input-fields li:last-child{
	margin-bottom: 0;
}

/* tv account  */

.select-tv{
	position: relative;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	background-color: var(--airtime-secondary-color);
	border: 1.5px solid #8b6a95;
	border-radius: 60px;
	margin-top: 24px;
	cursor: pointer;
	z-index: 1;
}

.tv-acc-input-fields{
	position: relative;
	width: 100%;
	margin-top: 22px;
	z-index: 0;
}

.tv-acc-input-form-wrapper{
	position: relative;
	top: 10px;
	text-align: right;
	display:list-item;

}

.tv-acc-input-form-wrapper li span{
	font-size: 12px;
	color: var(--airtime-primary-color);
}

.tv-primary-cta{
	width: 40%;
    font-size: 16px;
    color: var(--primary-color);
    background-color: var(--primary-color-100);
    border-radius: 60px; 
    cursor: pointer;
	margin-bottom: 12px;
	top: 10px;
    padding: 10px;
	
}




/*Responsive Mobile Sidebar Styling
@media only screen and (max-width: 768px) {
	.sidebar-container{
		display: none; display to block
		z-index: 100;
		position: fixed;
		width: 100%;
		height: 100vh;
		background-color: rgba(30, 35, 48, 0.9);
	}

	.sidebar{
		background-color: var(--bg-color);
		position: fixed;
		top: 0;
		right: 0;
        height: 100vh;
        z-index: 120;
        border-radius: 32px 0px 0px 32px;
    }

	.sidebar-wrapper{
        padding: 24px;
		position: relative;
    }

	.sidebar-close{
		text-align: right;
		padding: 8px;
	}

	.sidebar-close-icon{
		height: 30px;
		width: 30px;
		cursor: pointer;
	}

	.sidebar-product-list{
		margin-top: 24px;
	}

	.sidebar-product-row{
		display: flex;
		align-items: center;
		margin-bottom: 24px;
	}
	
	.sidebar-product-row:last-child{
		margin-bottom: 0px;
	}
	
	.sidebar-product{
		width: 100%;
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 16px 24px;
		border-radius: 60px;
		cursor: pointer;
	}

	.sidebar-product-name{
		font-size: 18px;
		font-weight: 600;
	}
	
	.sidebar-product-icon{
		width: 22px;
		height: 22px;
	}
	
	.sidebar-product:hover{
		background-color: var(--primary-color-50);
		transition: all .3s;
		transition-timing-function: ease-in;
	}
	
	.sidebar-product:focus{
		background-color: #3F4453;
		transition: all .3s;
		transition-timing-function: ease-in;
	}

	.sidebar-cta{
		position: relative;
		width: 100%;
	}

	.sidebar-cta-wrapper{
		position: fixed;
		right: 24px;
		bottom: 24px;
		min-width: 50%;
		width: auto !important;
		height: auto !important;
		z-index: -100;
	}

	.sidebar-cta-login{
		margin-top: 16px;
		width: 100%;
	}
}
*/