@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

:root {
    --primary-clr: #502274;
    --white: #fff;
    --text-clr: #464646;
    --tabs-list-bg-clr: #dfc8fd;
    --btn-hvr: #502274;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: "Open Sans", sans-serif;
}

.wrapper{
    background: var(--primary-clr);
    font-size: 12px;
    color: var(--text-clr);
    min-height: 100vh;
    padding: 0 20px;
}

.flex_align_justify{
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    width: 100%;
    height: 480px;
    background: var(--white);
    /*display: flex;*/
    border-radius: 3px;
    overflow: hidden;
    padding: 1rem;
}

.mt-2{
    margin-top: 2rem;
}

.mt-1{
    margin-top: 1rem;
}

.img-center{
    object-fit: none;
    object-position: center center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.bold{
    font-weight: bold;
}

.sm{
    font-size: 1rem;
}

.flex{
    display: flex;
}

.ml-1{
    margin-left: 0.5rem;
}