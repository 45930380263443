.checkout{
    padding: 20px;
    width: 100%;
}

.checkout-wrapper{
    margin-top: 92px;
}

.payment-summary{
    position: relative;
    background-color: #EAF0FF;
    padding: 32px;
    border-radius: 20px;
}

.payment-summary-header{
    text-align: center;
    font-weight: 800;
    font-size: 16px;
}

.payment-summary-details{
    margin: 32px 0px;
    border-top: 1px dashed #9CA1B1;
    border-bottom: 1px dashed #9CA1B1;
    padding-top: 24px;
}

.payment-summary-details li{
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-summary-details-bold{
    font-weight: 800;
    text-align: right;
}

.payment-summary-total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
}

.circle1{
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--bg-color);
    border-radius: 60px;
    top: 70px;
    left: -12px;
}

.circle2{
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--bg-color);
    border-radius: 60px;
    top: 70px;
    right: -12px;
}

.circle3{
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--bg-color);
    border-radius: 60px;
    bottom: 78px;
    left: -12px;
}

.circle4{
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--bg-color);
    border-radius: 60px;
    bottom: 78px;
    right: -12px;
}

.stripe-placeholder{
    margin: 48px 0px;
}

.stripe-placeholder-header{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 32px;
}

#payment-element{
    margin-bottom: 20px;
}