/*Responsive Mobile Fisrt*/
.homepage-container{
    height: 100%;
    /* height: 920px; */
    background-color: var(--primary-color);
}

/*Body Content Styling*/
.body-content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.body-content-wrappper{
    position: relative;
    margin: 134px 20px 0px 20px;
}

.body-content-main-header-flex{
    display: flex;
    width: 100%;
    justify-content: center;
}

.body-content-main-header{
    width: 85%;
    font-size: var(--mob-header3);
    text-align: center;
    color: var(--secondary-color);
    font-weight: 600;
    line-height: 48px;
}

.recipient-section-wrap{
    margin: 48px 0px;
}

.recipient-section{
    width: 100%;
    background-color: var(--bg-color);
    padding: 32px 16px 16px 16px;
    border-radius: 24px;
}

.recipient-section-header{
    width: 100%;
    margin-bottom: 32px;
    font-size: var(--mob-header1);
    text-align: center;
    line-height: 24px;
}

.recipient-input{
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #BABFD1;
    border-radius: 60px;
    padding: 8px 20px 8px 8px;
    margin-bottom: 16px;
    width: 100%;
}

.recipient-input-wrapper{
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 8px;
    border-radius: 60px;
}

.country-flag{
    width: 24px;
    height: 24px;
    
}

.country-code{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0px 8px;
}

.arrow-down{
    width: 24px;
    height: 24px;
}

.recipient-number{
    width: 100%;
    margin-left: 12px;
    font-size: 16px;
}

.recipient-cta{
    width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 60px; 
    cursor: pointer;
}

.recipient-cta:hover{
    background-color: var(--primary-color-hover);
    transition: all .3s;
    transition-timing-function: ease-in;
}

.recipient-cta:active{
    background-color: var(--primary-color-hover);
}

.sender-location{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
}

.sender-location-wrapper{
    display: flex;
    align-items: center;
    padding: 12px 32px 12px 12px;
    background-color: #ffffff20;
    border-radius: 60px;
}

.sender-location-flag{
    width: 46px;
    height: 46px;
    margin-right: 20px;
    border-radius:50%;
    object-fit: cover;
    overflow: hidden;
}

.sender-location-text{
    font-size: var(--mob-header1);
    color: #ffffff50;
    line-height: 24px;
}

.sender-country{
    font-weight: 600;
    color: var(--bg-color);
    margin-left: 8px;
}





/*Homepage Footer Styling*/
.homepage-footer{
    width: 100%;
    /* margin-bottom: 30px; */
}

.homepage-footer-wrapper{
    display: flex;
    justify-content: center;
}

.homepage-footer-text{
    margin: 74px 0px 0px 20px;
    color: #ffffff8b;
}


/*Homepage Footer Terms and Conditions Styling*/
.homepage-footer-terms{
    width: 100%;
    margin-top:0;
}

.homepage-footer-terms-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff8b;
    flex-direction: row;
}

.homepage-footer-terms-text{
    color: #ffffff8b;
}


/*Homepage Footer Support Styling*/
.homepage-footer-support{
    width: 100%;
    margin-top:0;
    padding-bottom: 6%;
}

.homepage-footer-support-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff8b;
    flex-direction: row;
}



/*Promo card styling*/
.promo{
	width: 100%;
    margin-bottom: 16px;
}

.promo-wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #FFF5DB;
	padding: 20px;
	border-radius: 20px;
}

.giftbox-wrap{
	display: flex;
	align-items: center;
	gap: 16px;
}

.giftbox-gif{
	height: 60px;
	width: 60px;
}

.promo-message{
	font-size: 16px;
    font-weight: 800;
}

.promo-cta{
	padding: 8px 16px;
	border-radius: 60px;
	color: var(--bg-color);
	background-color: #F87D2D;
}

#filter-white{

    filter: invert(96%) sepia(6%) saturate(260%) hue-rotate(270deg) brightness(118%) contrast(100%);
}















/*Responsive Desktop view*/
@media only screen and (min-width: 768px){
    
}



    
    
    
    
    
     
    
    
    
/*Responsive Tablet view*/
@media only screen and (min-width: 768px) and (max-width: 1024px){
  

}
    

    
    
    
    
    
    
    



/*Responsive Mobile Navbar view*/
@media only screen and (max-width: 768px) {
    
}



    