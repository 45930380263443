/*Sign up Form Field*/
.main-profile-container{
    position: relative;
    height: auto;
    top: 92px;
    width: 100%;
    background-color: #ffffff;
}

.main-profile-wrapper{
    margin: 20px; 
    width: auto;
}

.profile-section{
    padding-bottom: 48px;
    border-bottom: 1px solid #DDE2F4;
}

.profile-section-header{
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 20px;
}

.profile-section-imagename{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
}

.profile-section-image{
    height: 94px;
    width: 94px;
    background-color: var(--primary-color-50);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
}

.profile-section-icon{
    height: 32px;
    width: 32px;
}

.profile-section-username{
    position: relative;
}

.profile-section-name{
    font-size: var(--mob-header1);
    font-weight: 600;
    margin-bottom: 12px;
}

.profile-section-edit{
    color: var(--primary-color);
}


.profile-input-fields{
	position: relative;
	width: 100%;
}

.profile-input:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.profile-input:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.profile-input-fields li:last-child{
	margin-bottom: 0;
}

.profile-input{
	width: 100%;
	font-size: 16px;
	background-color: #5b5e6600;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.profile-password{
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	padding: 20px 28px;
	margin-bottom: 20px;
	border: var(--border);
	border-radius: 60px;
}

.profile-password-input{
	width: 100%;
	font-size: 16px;
}

.profile-password:hover{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.profile-password:focus{
	border: 1.5px solid #502274;
	transition: all .3s;
    transition-timing-function: ease-in;
}

.profile-modal-ctas{
	width: 100%;
}

.profile-primary-cta{
	width: 100%;
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    border-radius: 60px; 
    cursor: pointer;
    padding: 20px;
}

.profile-modal-ctas-small{
    width: 100%;
    text-align: right;
    margin-top: 32px;
}


.profile-primary-cta-small{
    font-size: 16px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    border-radius: 60px; 
    cursor: pointer;
    padding: 20px 32px;
}





.history-section{
    width: 100%;
    margin-top: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid #DDE2F4;
}

.history-section-header-wrap{
    display: flex;
    justify-content: space-between;
}

.history-section-header{
    font-size: 24px;
    font-weight: 800;
}

.history-section-all{
    font-size: 16px;
    font-weight: 800;
    color: var(--primary-color);
}

.history-transaction-rows{
    margin: 20px 0px;
}

.history-transaction-details{
    background-color: var(--primary-color-50);
    border-radius: 24px;
    padding: 24px;
    list-style: none;
    text-decoration: none;
}

.history-transaction-details li{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.history-transaction-details li:last-child{
    margin-bottom: 0;
}

.history-details-bold{
    font-weight: 800;
    text-align: right;
}





.Security-section{
    margin-top: 48px;
    padding-bottom: 48px;
}

/*Promo card styling*/
.promo{
	width: 100%;
    margin-top: 10%;
    margin-bottom: 16px;
}

.promo-wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #FFF5DB;
	padding: 20px;
	border-radius: 20px;
}

.giftbox-wrap{
	display: flex;
	align-items: center;
	gap: 16px;
}

.giftbox-gif{
	height: 60px;
	width: 60px;
}

.promo-message{
	font-size: 16px;
    font-weight: 800;
}

.promo-cta{
	padding: 8px 16px;
	border-radius: 60px;
	color: var(--bg-color);
	background-color: #F87D2D;
}