@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

:root {
  --primary-clr: #502274;
  --white: #fff;
  --text-clr: #464646;
  --tabs-list-bg-clr: #dfc8fd;
  --btn-hvr: #502274;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Open Sans", sans-serif;
}

.wrapper{
  background: var(--primary-clr);
  font-size: 12px;
  color: var(--text-clr);
}

.flex_align_justify{
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper{
  min-height: 100vh;
  padding: 0 20px;
}

.tc_wrap{
  width: 700px;
  max-width: 100%;
  height: 480px;
  background: var(--white);
  display: flex;
  border-radius: 3px;
  overflow: hidden;
}

.tc_wrap .tabs_list{
  width: 25%;
  background: var(--tabs-list-bg-clr);
  height: 100%;
}

.tc_wrap .tabs_content{
  width: 100%;
  padding: 0 10px 0 20px;
  height: 100%;
}

.tc_wrap .tabs_content .tab_head,
.tc_wrap .tabs_content .tab_foot{
  color: var(--primary-clr);
  padding: 25px 0;
  height: 70px;
}

.tc_wrap .tabs_content .tab_head{
  text-align: center;
}

.tc_wrap .tabs_content .tab_body{
  height: calc(100% - 90px);
  overflow: auto;
  width: 100%;
}

.tc_wrap .tabs_list ul{
  padding: 70px 20px;
  text-align: right;
}

.tc_wrap .tabs_list ul li{
  padding: 10px 0;
  position: relative;
  margin-bottom: 3px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_list ul li:before{
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  width: 2px;
  height: 100%;
  background: var(--primary-clr);
  opacity: 0;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_list ul li.active,
.tc_wrap .tabs_list ul li:hover{
  color: var(--primary-clr);
}

.tc_wrap .tabs_list ul li.active:before{
  opacity: 1;
}

.tc_wrap .tabs_content .tab_body .tab_item h3{
  padding-top: 10px;
  margin-bottom: 10px;
  color: var(--primary-clr);
  width:100%;
}

.tc_wrap .tabs_content .tab_body .tab_item p{
  margin-bottom: 20px;
}

.tc_wrap .tabs_content .tab_body .tab_item.active{
  display: block !important;
}

.tc_wrap .tabs_content .tab_foot button{
    width: 125px;
    padding: 5px 10px; 
    background: transparent;
    border: 1px solid;
    border-radius: 25px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.tc_wrap .tabs_content .tab_foot button.decline{
  margin-right: 15px;
  border-color: var(--tabs-list-bg-clr);
  color: var(--tabs-list-bg-clr);
}

.tc_wrap .tabs_content .tab_foot button.agree{
   background: var(--primary-clr);
  border-color: var(--primary-clr);
  color: var(--white);
}

.tc_wrap .tabs_content .tab_foot button.decline:hover{
  background: var(--tabs-list-bg-clr);
  color: var(--white);
}

.tc_wrap .tabs_content .tab_foot button.agree:hover{
  background: var(--btn-hvr);
  border-color: var(--btn-hvr);
}

.list-wrap {
    padding:5px;
    border-radius:5px;
    width:100%;
  }
  
  div.list {
    counter-reset: list-number;
  }
  div.list div:before {
    counter-increment: list-number;
    content: counter(list-number);
    
    margin-right: 10px;
    width:20px;
    height:20px;
    display:inline-flex;
    align-items:center;
    justify-content: center;
    font-size:10px;
    background-color: var(--primary-clr);
    border-radius:50%;
    color:#fff;
  }

  .para{
      position: relative;
      left:15%;
  }