/*Responsive Mobile Fisrt*/
.purchasepage{
    width: 100%;
    display: flex;
    align-items: center;
}

.purchasepage-wrapper{
    margin: 276px 20px 0px 20px;
    width: 100%;
}



/*Stylig for Data Card*/
.data-purchase-card{
    background-color: var(--primary-color);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 32px;
}

.data-product-logo{
    margin-bottom: 8px;
    object-fit: cover;
    width:13%;
    height: 13%;
    border-radius: 50%;
}

.data-purchase{
    font-size: var(--mob-header2);
    font-weight: 800;
    text-align: center;
    color: var(--bg-color);
}

.data-purchase-cta{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 8px 12px;
    border-radius: 16px;
}

.data-purchase-amount{
    font-size: var(--mob-header1);
    font-weight: 800;
    text-align: center;
}


/*Stylig for Airtime Card*/
.airtime-purchase-card{
    background-color: var(--airtime-primary-color);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 32px;
}

.airtime-product-logo{
    margin-bottom: 8px;
    object-fit: cover;
    width:13%;
    height: 13%;
    border-radius: 50%;
}

.airtime-purchase{
    font-size: var(--mob-header2);
    font-weight: 800;
    text-align: center;
    color: var(--bg-color);
}

.airtime-purchase-cta{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--airtime-secondary-color);
    padding: 8px 12px;
    border-radius: 16px;
}

.airtime-purchase-amount{
    font-size: var(--mob-header1);
    font-weight: 800;
    text-align: center;
}


/*Stylig for Electricity Card*/
.utility-purchase-card{
    background-color: var(--utility-primary-color);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 32px;
}

.utility-product-logo{
    margin-bottom: 8px;
    object-fit: cover;
    width:13%;
    height: 13%;
    border-radius: 50%;
}

.utility-purchase{
    font-size: var(--mob-header2);
    font-weight: 800;
    text-align: center;
    color: var(--bg-color);
}

.utility-purchase-cta{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--utility-secondary-color);
    padding: 8px 12px;
    border-radius: 16px;
}

.utility-purchase-amount{
    font-size: var(--mob-header1);
    font-weight: 800;
    text-align: center;
}



/*Stylig for Electricity Card*/
.tv-purchase-card{
    background-color: var(--tv-primary-color);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 32px;
}

.tv-product-logo{
    margin-bottom: 8px;
    object-fit: cover;
    width:13%;
    height: 13%;
    border-radius: 50%;
}

.tv-purchase{
    font-size: var(--mob-header2);
    font-weight: 800;
    text-align: center;
    color: var(--bg-color);
}

.tv-purchase-cta{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tv-secondary-color);
    padding: 8px 12px;
    border-radius: 16px;
}

.tv-purchase-amount{
    font-size: var(--mob-header1);
    font-weight: 800;
    text-align: center;
}


/*Styling for Voucher Purchase*/
.voucher-purchase-card{
    background-color: var(--voucher-primary-color);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 32px;
}

.voucher-product-logo{
    margin-bottom: 8px;
    object-fit: cover;
    width:13%;
    height: 13%;
    border-radius: 50%;
}

.voucher-purchase{
    font-size: var(--mob-header2);
    font-weight: 800;
    text-align: center;
    color: var(--bg-color);
}

.voucher-purchase-cta{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--voucher-secondary-color);
    padding: 8px 12px;
    border-radius: 16px;
}

.voucher-purchase-amount{
    font-size: var(--mob-header1);
    font-weight: 800;
    text-align: center;
}


/*Promo special Card styling*/
.promo-special-card{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.gif-margin{
    margin-top: -4px;
}

